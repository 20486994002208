import * as React from 'react';

import { theme } from '../../../../styles/2020/theme';

import { Clamp } from '../../layout/Clamp';
import { Column } from '../../layout/Column';
import { Sidebar } from '../../layout/Sidebar';

import { H2, P, Text } from '../Primitives';

type SideBySideStepPanelProps = {
  title?: string;
  text?: string;
  step?: number;
  contentMax?: React.CSSProperties['maxWidth'];
  image: React.ReactNode;
};

/**
 * Fairly specific to the getting started page (other/start/)
 */
export function SideBySideStepPanel({
  title,
  text,
  step,
  image,
  contentMax = '550px',
  children,
}: React.PropsWithChildren<SideBySideStepPanelProps>) {
  return (
    <Sidebar sideWidth="350px">
      <Clamp max={contentMax}>
        <Column space={theme.space.xxxs}>
          {step && (
            <Text as="h3" color="green2" bold smallCaps>
              Step {step}
            </Text>
          )}

          {title && <H2 as={step ? 'h4' : 'h3'}>{title}</H2>}
          {text ? <P>{text}</P> : children}
        </Column>
      </Clamp>

      <Clamp max="550px">{image}</Clamp>
    </Sidebar>
  );
}
