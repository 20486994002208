import * as React from 'react';

import type { HeadProps, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { Link } from 'stile-shared/src/components/2020/common/Link';
import { H2, P, PageHeading, Ul } from 'stile-shared/src/components/2020/common/Primitives';
import { SideBySideStepPanel } from 'stile-shared/src/components/2020/common/SideBySideStepPanel';
import { Column } from 'stile-shared/src/components/2020/layout/Column';
import { FullWidthBackground } from 'stile-shared/src/components/2020/layout/FullWidthBackground';
import { Row } from 'stile-shared/src/components/2020/layout/Row';

import { theme } from 'styles/theme';

import { PageLayout } from 'templates/PageLayout';
import { SEO } from 'templates/SEO';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export function Head(props: HeadProps) {
  return (
    <SEO
      title="Getting started"
      description="First steps to getting started with the Stile app"
      slug={props.location.pathname}
    />
  );
}

function StartPage(props: PageProps) {
  return (
    <PageLayout {...props}>
      <FullWidthBackground forwardedAs="section" intrinsic={false} invertColors>
        <Column space={theme.space.section}>
          <Row>
            <PageHeading>Getting started with Stile</PageHeading>
          </Row>

          <SideBySideStepPanel
            step={1}
            title="Go to stileapp.com"
            text="Log in with your school email address and password."
            image={
              <StaticImage src="../../../../stile-shared/assets/images/start/step1.png" alt="" />
            }
          />

          <SideBySideStepPanel
            step={2}
            title="Create a subject"
            text="Think of subjects as folders for your Stile lessons. We suggest creating a
              subject for each year level that you teach."
            image={
              <StaticImage src="../../../../stile-shared/assets/images/start/step2.png" alt="" />
            }
          />

          <SideBySideStepPanel
            step={3}
            title="Click on a subject"
            text="Once you have created a subject, select it. You can see all your subjects by
              clicking “Home”."
            image={
              <StaticImage src="../../../../stile-shared/assets/images/start/step3.png" alt="" />
            }
          />

          <SideBySideStepPanel
            step={4}
            title="Browse the Library"
            text="The Library shows all available units. You can click on any unit to preview
              its lessons."
            image={
              <StaticImage src="../../../../stile-shared/assets/images/start/step4.png" alt="" />
            }
          />

          <SideBySideStepPanel
            step={5}
            title="Find a unit"
            text="You can search using a keyword, or filter by subject or year level."
            image={
              <StaticImage src="../../../../stile-shared/assets/images/start/step5.png" alt="" />
            }
          />

          <SideBySideStepPanel
            step={6}
            title="Add the unit to your subject"
            text="Adding a unit from the library creates your own copy of the unit in your subject."
            image={
              <StaticImage src="../../../../stile-shared/assets/images/start/step6.png" alt="" />
            }
          />
        </Column>
      </FullWidthBackground>

      <FullWidthBackground forwardedAs="section" intrinsic={false}>
        <Column space={theme.space.section}>
          <Row>
            <H2 size="xxl">Adding students</H2>
          </Row>

          <SideBySideStepPanel
            title="Go to Students page"
            text="Click the green Students button to go to the Students page of your subject."
            image={
              <StaticImage src="../../../../stile-shared/assets/images/start/step7.png" alt="" />
            }
          />

          <SideBySideStepPanel
            title="Instruct your students to join your class"
            image={
              <StaticImage src="../../../../stile-shared/assets/images/start/step8.png" alt="" />
            }
          >
            <P>
              Ask your students to navigate to{' '}
              <Link url="https://stileapp.com/join/">stileapp.com/join</Link> in their web browsers
              and enter the class code.
            </P>

            <P>
              You can:
              <Ul space={theme.space.xxs}>
                <li>Display the class code in front of the class</li>
                <li>Email them the class code.</li>
                <li>Screen share over video conferencing.</li>
              </Ul>
            </P>
          </SideBySideStepPanel>

          <SideBySideStepPanel
            title="Or Invite students via email"
            text="Alternatively, you can enrol your students by sending them an invitation email."
            image={
              <StaticImage src="../../../../stile-shared/assets/images/start/step9.png" alt="" />
            }
          />
        </Column>
      </FullWidthBackground>

      <FullWidthBackground forwardedAs="section" intrinsic={false} invertColors>
        <Column space={theme.space.section}>
          <Row>
            <H2 size="xxl">Lesson management</H2>
          </Row>

          <SideBySideStepPanel
            title="Releasing lessons"
            text="You are able to hide all lessons or release them one by one. You can also release lessons to individual students."
            image={
              <StaticImage src="../../../../stile-shared/assets/images/start/step10.png" alt="" />
            }
          />
        </Column>
      </FullWidthBackground>

      <TextBlock>
        <H2>Before running your first class</H2>

        <Ul space={theme.space.xxs}>
          <li>Remind your students to bring their charged laptop or tablet to class.</li>
          <li>Have a read through the first few lessons of your unit.</li>
          <li>
            By default, all lessons in a unit will be available to your students with the exception
            of tests.
          </li>
        </Ul>
      </TextBlock>

      <NextPageBlock path="/what-is-stile/stile-classroom/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default StartPage;
